import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import "./App.css";
import Logo from "./logo.png";

const url = process.env.REACT_APP_PUBLIC_SUPABASE_API_URL as string;
const anonKey = process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY as string;

const api = createClient(url, anonKey);

function App() {
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setCofirmPassword] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    api.auth.getSession().then(({ data: { session } }) => {
      console.log("session", session);
      if (!session) {
        setErrorMsg(
          "Unable to retrieve data. Please try initiating the reset password from the app again or contact support."
        );
      }
    });

    const {
      data: { subscription },
    } = api.auth.onAuthStateChange((event, _session) => {
      if (event === "PASSWORD_RECOVERY") {
        setIsPasswordRecovery(true);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleUpdate = async () => {
    setErrorMsg("");
    if (newPassword !== confirmPassword) {
      setErrorMsg("Passwords must match");
      return;
    }

    const { error } = await api.auth.updateUser({
      password: newPassword,
    });

    if (!error) {
      setSuccess(true);
    } else {
      setErrorMsg(error.message);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F2F4F7",
      }}
    >
      <img
        src={Logo}
        height={64}
        width={64}
        alt="Renew Logo"
        style={{ marginBottom: 16 }}
      />
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          height: "80%",
          maxHeight: "700px",
          width: "80%",
          maxWidth: "400px",
          padding: 16,
          borderRadius: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {errorMsg && (
            <div
              style={{
                display: "flex",
                backgroundColor: "#E63946",
                padding: 8,
                borderRadius: 4,
                width: "100%",
              }}
            >
              <p style={{ color: "white" }}>{errorMsg}</p>
            </div>
          )}
          {isPasswordRecovery ? (
            <>
              {success ? (
                <div style={{ textAlign: "center" }}>
                  <h2 className="title">Password reset</h2>
                  <p className="subtitle">
                    Your password was updated. Please go back to our mobile app
                    and login.
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h2 className="title">Set new password</h2>
                  <p className="subtitle">
                    Your new password must be different than previously used
                    passwords.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 24,
                      width: "75%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className="label">Password</p>
                      <input
                        type="password"
                        value={newPassword}
                        onChange={(val) => setNewPassword(val.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className="label">Confirm Password</p>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(val) => setCofirmPassword(val.target.value)}
                      />
                    </div>
                    <button
                      onClick={handleUpdate}
                      style={{
                        backgroundColor: "#363F72",
                        color: "white",
                        borderWidth: 0,
                        borderRadius: 8,
                        height: 40,
                        fontWeight: "bold",
                      }}
                    >
                      Reset password
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <h1>Loading...</h1>
            </div>
          )}
          <a
            style={{ marginTop: "auto" }}
            href="https://winningyourmind.app/#contact"
            target="_blank"
            rel="noreferrer"
          >
            <p> Need Help? Contact Support</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
